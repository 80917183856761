// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-19-01-2020-implisiittinen-eksplisiittinen-data-js": () => import("./../../../src/pages/blog/19-01-2020/implisiittinen-eksplisiittinen-data.js" /* webpackChunkName: "component---src-pages-blog-19-01-2020-implisiittinen-eksplisiittinen-data-js" */),
  "component---src-pages-blog-20-01-2020-konversioprosentin-kasvattaminen-js": () => import("./../../../src/pages/blog/20-01-2020/konversioprosentin-kasvattaminen.js" /* webpackChunkName: "component---src-pages-blog-20-01-2020-konversioprosentin-kasvattaminen-js" */),
  "component---src-pages-blog-22-01-2020-suosittelujarjestelmat-js": () => import("./../../../src/pages/blog/22-01-2020/suosittelujarjestelmat.js" /* webpackChunkName: "component---src-pages-blog-22-01-2020-suosittelujarjestelmat-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-ga-js": () => import("./../../../src/pages/integrations/ga.js" /* webpackChunkName: "component---src-pages-integrations-ga-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-requirements-js": () => import("./../../../src/pages/requirements.js" /* webpackChunkName: "component---src-pages-requirements-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

